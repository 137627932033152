import React, { useState, useRef } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { useLocation } from '@reach/router';

const useStyles = makeStyles({
  root: {
    // 20px is sum of the paper padding top & bottom
    height: 'calc(100% - 20px)',
  },

  form: {
    padding: 24,

    '& .MuiTextField-root, .MuiButton-root, .MuiInput-root': {
      width: '100%',
    },
  },

  addButton: {
    '& .MuiButton-label': {
      flexWrap: 'wrap',
    },
    '& .MuiTypography-caption': {
      flexBasis: '100%',
      textAlign: 'center',
    },
  },

  selfEnd: {
    alignSelf: 'flex-end',
  },
});

const requestTypes = [
  'Price.Form.requestType.1',
  'Price.Form.requestType.2',
  'Price.Form.requestType.3',
  'Price.Form.requestType.4',
  'Price.Form.requestType.5',
  'Price.Form.requestType.6',
];

const PriceForm = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const form = useRef(null);
  const location = useLocation();

  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [typesRequested, setTypesReauested] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [description, setDescription] = useState('');

  const handleChangeName = (event) => {
    setName(event.target.value);
  };
  const handleChangeCompany = (event) => {
    setCompany(event.target.value);
  };
  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };
  const handleChangePhone = (event) => {
    setPhone(event.target.value);
  };
  const handleChangeType = (event) => {
    setTypesReauested(event.target.value);
  };
  const handleSelectedFile = (event) => {
    if (event.target.files[0].size < 6000000) {
      setSelectedFiles(Array.from(event.target.files));
    } else {
      alert(t('errors.fileSize'));
    }
  };
  const handleChangeDescription = (event) => {
    setDescription(event.target.value);
  };

  return (
    <Paper elevation={0} className={classes.root}>
      <Typography variant="h5" align="center" gutterBottom>
        {t('Price.Form.title')}
      </Typography>
      <Divider />
      <form
        ref={form}
        className={classes.form}
        method="POST"
        action={`https://formsubmit.co/${process.env.GATSBY_FORM_SEND_EMAIL}`}
        encType="multipart/form-data"
      >
        <input
          type="hidden"
          name="_subject"
          value="Request Price Form Submission"
        />
        <input type="hidden" name="_template" value="table" />
        {/* _honey: avoid spam by fooling scrapers */}
        <input type="text" name="_honey" style={{ display: 'none' }} />
        <input type="hidden" name="_url" value={location.href} />

        <Grid container spacing={6} justifyContent="center">
          <Grid item xs={12} sm={6} lg={3}>
            <TextField
              label={t('Price.Form.name')}
              id="name"
              name="Name"
              value={name}
              onChange={handleChangeName}
              required
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={3}>
            <TextField
              label={t('Price.Form.company')}
              id="company"
              name="Company"
              value={company}
              onChange={handleChangeCompany}
              required
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={3}>
            <TextField
              label={t('Price.Form.email')}
              id="email"
              name="Email"
              type="email"
              value={email}
              onChange={handleChangeEmail}
              required
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={3}>
            <TextField
              label={t('Price.Form.phone')}
              id="phone"
              name="Phone"
              type="tel"
              value={phone}
              onChange={handleChangePhone}
              required
            />
          </Grid>

          <Grid item xs={12} sm={4} className={classes.selfEnd}>
            <FormControl fullWidth required>
              <InputLabel id="request-type">
                {t('Price.Form.requestType.title')}
              </InputLabel>
              <Select
                labelId="request-type"
                name="RequestType"
                id="request-type"
                aria-describedby="requestTypeNote"
                multiple
                value={typesRequested}
                onChange={handleChangeType}
                renderValue={(selected) =>
                  selected.map((selected) => t(selected)).join(' - ')
                }
              >
                {requestTypes.map((type) => (
                  <MenuItem key={type} value={t(type)}>
                    <Checkbox checked={typesRequested.indexOf(t(type)) > -1} />
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography variant="body2">{t(type)}</Typography>
                      }
                    />
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText id="requestTypeNote">
                {t('Price.Form.requestType.helperText')}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4} className={classes.selfEnd}>
            <Button
              variant="outlined"
              color="secondary"
              component="label"
              className={classes.addButton}
              endIcon={<AddRoundedIcon />}
            >
              {selectedFiles.map((file) => (
                <Typography key={file.name} variant="caption">
                  {file.name}
                </Typography>
              ))}

              {t('Price.Form.file.title')}
              <input
                type="file"
                id="file"
                aria-describedby="file"
                name="File"
                hidden
                // multiple
                onChange={handleSelectedFile}
              />
            </Button>
            <FormHelperText id="file">
              {t('Price.Form.file.helperText')}
            </FormHelperText>
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              label={t('Price.Form.description')}
              id="description"
              name="Description"
              required
              multiline
              rows={2}
              value={description}
              onChange={handleChangeDescription}
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={3}>
            <Button type="submit" variant="outlined">
              {t('‌Button.send')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default PriceForm;

import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { graphql } from 'gatsby';

import Template from '../components/Template';
import Metadata from '../components/Metadata';
import BackgroundImage from '../components/BackgroundImage';
import HeaderPaper from '../components/HeaderPaper';
import PriceForm from '../components/PriceForm';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      minHeight: '100vh',
    },
  },

  headerWrapper: {
    margin: '-20px -20px 0',
  },

  priceFormWrapper: {
    flexGrow: 1,
    marginTop: 16,
  },
}));

const Price = ({ data }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const backgroundImg = data.priceBG.childImageSharp.gatsbyImageData;

  return (
    <Template>
      <Metadata pageTitle={t('Menu.Price')} />

      <Grid container direction="column" wrap="nowrap" className={classes.root}>
        <Grid item className={classes.headerWrapper}>
          <BackgroundImage bgImage={backgroundImg}>
            <HeaderPaper title={t('Menu.Price')} text={t('Price.Header')} />
          </BackgroundImage>
        </Grid>

        <Grid item className={classes.priceFormWrapper}>
          <PriceForm />
        </Grid>
      </Grid>
    </Template>
  );
};

export default Price;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    priceBG: file(relativePath: { eq: "projects/contact.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: BLURRED)
      }
    }
  }
`;
